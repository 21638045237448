define("discourse/plugins/discourse-legal-tools/discourse/templates/connectors/admin-user-controls-after/download-all-wrapper", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.showAdminUserExport}}
    <DButton @action={{this.exportAdminUserArchive}}
             @actionParam={{@outletArgs.model}}
             @label="user.download_archive.button_text"
             @icon="download" />
  {{/if}}
  */
  {
    "id": "VXU59ywr",
    "block": "[[[41,[30,0,[\"showAdminUserExport\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@action\",\"@actionParam\",\"@label\",\"@icon\"],[[30,0,[\"exportAdminUserArchive\"]],[30,1,[\"model\"]],\"user.download_archive.button_text\",\"download\"]],null],[1,\"\\n\"]],[]],null]],[\"@outletArgs\"],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-legal-tools/discourse/templates/connectors/admin-user-controls-after/download-all-wrapper.hbs",
    "isStrictMode": false
  });
});