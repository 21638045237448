define("discourse/plugins/discourse-legal-tools/discourse/initializers/legal-edits", ["exports", "discourse/lib/plugin-api", "discourse/lib/export-csv"], function (_exports, _pluginApi, _exportCsv) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'legal-edits',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.8.12', api => {
        api.modifyClass("controller:preferences/account", {
          pluginId: "discourse-legal-tools",
          actions: {
            exportUserArchive() {
              const extendedUserDownload = this.siteSettings.legal_extended_user_download;
              if (extendedUserDownload) {
                this.dialog.yesNoConfirm({
                  message: I18n.t("user.download_archive.confirm_all"),
                  didConfirm: () => {
                    (0, _exportCsv.exportUserArchive)();
                  }
                });
              } else {
                this._super();
              }
            }
          }
        });
      });
    }
  };
});